import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { reportWebVitals } from './reportWebVitals'; // Importation nomm�e correcte

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// Utilisation correcte de reportWebVitals
reportWebVitals(console.log);
