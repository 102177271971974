// src/Dashboard.js

import React, { useState, useEffect } from 'react';
import { Home, VideoCall, History, Settings, BarChart, Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import { getCustomVendors } from './api'; // Assurez-vous d'importer getCustomVendors

function Dashboard() {
  const [activeMenu, setActiveMenu] = useState('Home');
  const [subMenuSelection, setSubMenuSelection] = useState(null);
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [adminName, setAdminName] = useState('Admin');
  const [showLogoutMenu, setShowLogoutMenu] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState('form');
  const [popupMessage, setPopupMessage] = useState('');
  const [datenle, setDatenle] = useState('');
  const [stores, setStores] = useState([]); // Initialis� en tant que tableau
  const [vendorList, setVendorList] = useState([]); // Initialis� en tant que tableau
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const vendors = await getCustomVendors();
        setVendorList(vendors);
      } catch (error) {
        console.error('Erreur lors de la r�cup�ration des vendeurs:', error);
      }
    }
    fetchData();
  }, []);

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
  };

  const handleSubMenuToggle = () => {
    setIsSubMenuVisible(!isSubMenuVisible);
  };

  const handleSubMenuClick = (subMenuName) => {
    setSubMenuSelection(subMenuName);
    setIsSubMenuVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = 'https://live-discount.com';
  };

  const handleProfileHover = () => {
    setShowLogoutMenu(true);
  };

  const handleProfileLeave = () => {
    setShowLogoutMenu(false);
  };

  const handleCreateLiveEventClick = () => {
    setShowPopup(true);
    setPopupContent('form');
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handlePublish = async () => {
    const eventId = Math.floor(Math.random() * 1000000);
    const startTime = new Date(datenle);
    const eventDuration = 60; // Dur�e de l'�v�nement en minutes

    const storesData = stores.map(storeId => {
      const start = new Date(startTime);
      const end = new Date(startTime);
      end.setMinutes(start.getMinutes() + eventDuration);

      return {
        store_id: storeId,
        start_time: start.toISOString(),
        end_time: end.toISOString(),
      };
    });

    const liveLink = `https://srv493134.hstgr.cloud/live/${eventId}`;

    const liveEvent = {
      event_name: `Live Event ${eventId}`,
      start_date: startTime.toISOString(),
      end_date: new Date(startTime.getTime() + eventDuration * 60000).toISOString(),
      vendors_selected: JSON.stringify(storesData),
      products_selected: JSON.stringify([]),
      live_status: 'scheduled',
      live_link: liveLink,
      created_by: 1,
    };

    console.log('Sending live event data:', liveEvent);

    try {
      const response = await fetch('https://srv493134.hstgr.cloud/createLiveEvent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(liveEvent)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (data.success) {
        console.log('Live event created successfully:', data);
        setPopupMessage(`Votre �v�nement en direct est disponible ici : ${liveLink}`);
        setPopupContent('confirmation');
      } else {
        console.error('Error creating live event:', data);
        setPopupMessage('Error creating Live Event. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setPopupMessage('Error creating Live Event. Please try again.');
    }
  };

  const renderContent = () => {
    if (activeMenu === 'Analytics') {
      return (
        <div>
          {subMenuSelection === 'Live Events' && (
            <div id="bloch3ale">Contenu des sous-�l�ments Live Events</div>
          )}
          {subMenuSelection === 'Private Lives' && (
            <div id="bloch3apl">Contenu des sous-�l�ments Private Lives</div>
          )}
          {subMenuSelection === 'Stores' && (
            <div id="bloch3as">Contenu des sous-�l�ments Stores</div>
          )}
          {!subMenuSelection && <div id="bloch3">Contenu par d�faut pour Analytics</div>}
        </div>
      );
    }

    switch (activeMenu) {
      case 'Home':
        return (
          <div id="bloch3home">
            <button className="boutoncle" onClick={handleCreateLiveEventClick}>
              <Add /> CREATE A LIVE EVENT
            </button>
            <div className="bloch3ple">Contenu bloch3ple</div>
            <div className="bloch3ppl">Contenu bloch3ppl</div>
            <div className="titretn">Today's news</div>
            <div className="bloch3total">Contenu bloch3total</div>
          </div>
        );
      case 'Live Events':
        return (
          <div id="bloch3le">
            <div className="bloch3ple">Contenu bloch3ple</div>
            <div className="titreue">Upcoming events</div>
            <div className="bloch3ue">Contenu bloch3ue</div>
            <div className="titreoe">Older events</div>
            <div className="bloch3oe">Contenu bloch3oe</div>
          </div>
        );
      case 'Private Lives':
        return (
          <div id="bloch3pl">
            <div className="bloch3prl">Contenu bloch3prl</div>
            <div className="titreueprivate">Upcoming events</div>
            <div className="bloch3ueprivate">Contenu bloch3ueprivate</div>
            <div className="titreoeprivate">Older events</div>
            <div className="bloch3oeprivate">Contenu bloch3oeprivate</div>
          </div>
        );
      case 'History':
        return <div id="bloch3history">Contenu pour l'historique des �v�nements</div>;
      case 'Control Panel':
        return <div id="bloch3cp">Contenu pour le panneau de configuration</div>;
      default:
        return <div id="bloch3">Contenu par d�faut</div>;
    }
  };

  return (
    <div className="dashboard-container">
      <div className="bloc1">
        <div className="logo-container">
          <img
            src="https://live-discount.com/wp-content/uploads/2023/10/Logo_live_discount-1024x782.png"
            alt="Logo"
            className="logo"
          />
        </div>
        <ul className="menu1">
          <li className={activeMenu === 'Home' ? 'active' : ''} onClick={() => handleMenuClick('Home')}>
            <Home />
            Home
          </li>
          <li
            className={activeMenu === 'Live Events' ? 'active' : ''}
            onClick={() => handleMenuClick('Live Events')}
          >
            <VideoCall />
            Live Events
          </li>
          <li
            className={activeMenu === 'Private Lives' ? 'active' : ''}
            onClick={() => handleMenuClick('Private Lives')}
          >
            <VideoCall />
            Private Lives
          </li>
          <li
            className={activeMenu === 'History' ? 'active' : ''}
            onClick={() => handleMenuClick('History')}
          >
            <History />
            History
          </li>
          <li
            className={activeMenu === 'Control Panel' ? 'active' : ''}
            onClick={() => handleMenuClick('Control Panel')}
          >
            <Settings />
            Control Panel
          </li>
          {/* Ajout de Analytics avec le sous-menu */}
          <li
            className={activeMenu === 'Analytics' ? 'active' : ''}
            onMouseEnter={handleSubMenuToggle}
            onMouseLeave={handleSubMenuToggle}
          >
            <BarChart />
            Analytics
            {isSubMenuVisible && (
              <ul className="submenu">
                <li onClick={() => handleSubMenuClick('Live Events')}>Live Events</li>
                <li onClick={() => handleSubMenuClick('Private Lives')}>Private Lives</li>
                <li onClick={() => handleSubMenuClick('Stores')}>Stores</li>
              </ul>
            )}
          </li>
        </ul>
        <button className="bouton1" onClick={() => (window.location.href = 'https://live-discount.com/')}>
          GO TO DASHBOARD
        </button>
      </div>

      <div className="bloc2">
        <div className="bloch1">
          <span className="admin-name">{adminName}</span>

          <span
            className="admin-icon"
            onMouseEnter={handleProfileHover}
            onMouseLeave={handleProfileLeave}
          >
            <img
              src="https://img.icons8.com/?size=100&id=7820&format=png&color=000000"
              alt="Admin Icon"
            />
            {showLogoutMenu && (
              <div className="admin-menu">
                <a href="#" className="logout-btn" onClick={handleLogout}>
                  Logout
                </a>
              </div>
            )}
          </span>

          <span className="notifications-icon">
            <img src="https://img.icons8.com/material-outlined/24/alarm.png" alt="Notifications" />
          </span>
        </div>

        <div className="bloch2">
          <span className="welcome-text">
            Welcome, <strong style={{ color: '#7fd2f3' }}>{adminName}</strong>
          </span>
        </div>

        <div className="bloch3">{renderContent()} </div>
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="popupnle">
            {popupContent === 'form' ? (
              <>
                <div className="popup-title">New live event</div>
                <form className="formnle">
                  <label htmlFor="datenle">Date and Time:</label>
                  <input
                    type="datetime-local"
                    id="datenle"
                    value={datenle}
                    onChange={(e) => setDatenle(e.target.value)}
                  />
                  <label htmlFor="stores">Select Stores:</label>
                  <select
                    id="stores"
                    multiple
                    value={stores}
                    onChange={(e) =>
                      setStores(Array.from(e.target.selectedOptions, (option) => option.value))
                    }
                  >
                    {Array.isArray(vendorList) && vendorList.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.first_name}
                      </option>
                    ))}
                  </select>
                </form>
                <div className="popup-actions">
                  <button className="popup-delete" onClick={handlePopupClose}>
                    Delete
                  </button>
                  <button className="popup-publish" onClick={handlePublish}>
                    Publish
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="popup-title">New live</div>
                <p>{popupMessage}</p>
                <button className="popup-close" onClick={handlePopupClose}>
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;