import React from 'react';
import './Popup.css';

const Popup = ({ content, onClose, onAccept, onReject, onNext, products }) => {
  const renderContent = () => {
    switch (content) {
      case 'initial':
        return (
          <div>
            <p>Vous �tes invit�s � participer au live event</p>
            <button onClick={onAccept}>Accepter</button>
            <button onClick={onReject}>Refuser</button>
          </div>
        );
      case 'rejected':
        return <p>Votre participation a �t� annul�e</p>;
      case 'products':
        return (
          <div>
            <p>S�lectionnez les produits � ajouter au live event</p>
            <ul>
              {products.map(product => (
                <li key={product.id}>
                  <input type="checkbox" id={`product-${product.id}`} />
                  <label htmlFor={`product-${product.id}`}>{product.name}</label>
                </li>
              ))}
            </ul>
            <button onClick={onNext}>Suivant</button>
          </div>
        );
      case 'thankYou':
        return (
          <div>
            <p>Merci pour votre participation</p>
            <button onClick={onClose}>Fermer</button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default Popup;