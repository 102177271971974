import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './LiveEvent.css'; // Assurez-vous d'avoir un fichier CSS pour g�rer les styles.

const LiveEvent = () => {
  const { eventId } = useParams(); // R�cup�re l'eventId depuis l'URL
  const [videoStream, setVideoStream] = useState(null);

  useEffect(() => {
    // Utilisation de l'URL de flux vid�o sp�cifi�e
    setVideoStream(`https://srv493134.hstgr.cloud/live/${eventId}`);
  }, [eventId]);

  return (
    <div className="container">
      {/* Bloc des commentaires */}
      <div className="bloc blocchat">
        {/* Contenu vide */}
      </div>

      {/* Bloc de la vid�o en direct */}
      <div className="bloc blocvideo">
        <div id="progressBarContainer">
          <div id="progressBar"></div>
        </div>
        {videoStream ? (
          <video id="liveVideo" src={videoStream} autoPlay controls></video>
        ) : (
          <p>Le live n'est pas encore commenc�</p>
        )}
      </div>

      {/* Bloc des produits */}
      <div className="bloc blocproduit">
        {/* Contenu vide */}
      </div>
    </div>
  );
};

export default LiveEvent;
