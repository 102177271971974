// /root/go-live/src/config.js

module.exports = {
  wooCommerce: {
    url: 'https://live-discount.com/wp-json/wc/v3/',
    consumerKey: 'ck_3cf37f37cfaff6f0f20f3e9b5073edfdccc5d9f1',
    consumerSecret: 'cs_7b628eb9276da2395109963d02bc332f6555071e'
  },
  dokan: {
    url: 'https://live-discount.com/wp-json/dokan/v1/',
    consumerKey: 'ck_3cf37f37cfaff6f0f20f3e9b5073edfdccc5d9f1', // Utilisez les m�mes cl�s que WooCommerce si n�cessaire
    consumerSecret: 'cs_7b628eb9276da2395109963d02bc332f6555071e' // Utilisez les m�mes cl�s que WooCommerce si n�cessaire
  },
  database: {
    host: 'localhost',
    user: 'root',
    password: 'Avigail@2023',
    database: 'live_shopping'
  }
};