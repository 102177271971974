import { onCLS, onFCP, onFID, onINP, onLCP, onTTFB } from 'web-vitals';

// Fonction pour envoyer les m�triques � un service d'analyse
export function reportWebVitals(sendToAnalytics) {
  onCLS(sendToAnalytics);
  onFCP(sendToAnalytics);
  onFID(sendToAnalytics);
  onINP(sendToAnalytics);
  onLCP(sendToAnalytics);
  onTTFB(sendToAnalytics);
}
