// /root/go-live/src/api.js

import axios from 'axios';
import config from './config';

// Fonction pour obtenir les produits de WooCommerce
export async function getWooCommerceProducts() {
  try {
    const response = await axios.get(`${config.wooCommerce.url}products`, {
      auth: {
        username: config.wooCommerce.consumerKey,
        password: config.wooCommerce.consumerSecret
      }
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la r�cup�ration des produits WooCommerce:', error.message);
    throw error; // Relancer l'erreur pour gestion ult�rieure
  }
}

// Fonction pour obtenir les produits d'un vendeur sp�cifique
export async function getWooCommerceVendorProducts(vendorId) {
  try {
    const response = await axios.get(`${config.wooCommerce.url}products`, {
      auth: {
        username: config.wooCommerce.consumerKey,
        password: config.wooCommerce.consumerSecret
      },
      params: {
        author: vendorId // Filtrer les produits en fonction de l'ID du vendeur
      }
    });
    return response.data;
  } catch (error) {
    console.error(`Erreur lors de la r�cup�ration des produits du vendeur ${vendorId} :`, error.message);
    throw error;
  }
}

// Fonction pour obtenir les vendeurs Dokan
export async function getCustomVendors() {
  try {
    const response = await axios.get(`${config.dokan.url}stores`, {
      auth: {
        username: config.dokan.consumerKey,
        password: config.dokan.consumerSecret
      }
    });
    return response.data;
  } catch (error) {
    console.error('Erreur lors de la r�cup�ration des utilisateurs Dokan:', error.message);
    throw error;
  }
}