// src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
import LoginPage from './LoginPage';
import Dashboard from './Dashboard';
import VendorDashboard from './VendorDashboard';
import LiveEvent from './LiveEvent';
import { getWooCommerceProducts, getWooCommerceVendorProducts, getCustomVendors } from './api'; // Remplacez getWooCommerceVendors par getCustomVendors

function App() {
  const [products, setProducts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [vendorProducts, setVendorProducts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const wooProducts = await getWooCommerceProducts();
        const wooVendors = await getCustomVendors();
        setProducts(wooProducts);
        setVendors(wooVendors);
      } catch (error) {
        console.error("Erreur lors de la r�cup�ration des donn�es:", error);
      }
    }
    fetchData();
  }, []);

  const fetchVendorProducts = async (vendorId) => {
    try {
      const products = await get

WooCommerceVendorProducts(vendorId);
      setVendorProducts(products);
    } catch (error) {
      console.error(`Erreur lors de la r�cup�ration des produits pour le vendeur ${vendorId}:`, error);
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard products={products} vendors={vendors} />} />
        <Route path="/vendor_dashboard" element={<VendorDashboard fetchVendorProducts={fetchVendorProducts} vendorProducts={vendorProducts} />} />
        <Route path="/live/:eventId" element={<LiveEvent />} />
      </Routes>
    </Router>
  );
}

export default App;